const en = {
    lang: 'English', //当前语言
    languages: [     //语言文件
        {
            value: 'zh',
            name: '中文'
        },
        {
            value: 'en',
            name: 'English'
        }
    ],
    //以下为内容区域翻译   //英文
    bannerOne: {
        title: 'iTing Zoom Assistant-R6',
        introduce: 'Conference call translation is a good helper',
        explain1: 'Call recording | Call translation | Face to face translation',
    },
    bannerTwo: {
        title: 'AI Voice Mouse - V8',
        introduce: 'AI VOICE MOUSE MAKE TYPING SO EASY',
        explain1: 'Vocie Typing | 100 + Languages ',
    },
    contact: {
        link: 'Friendly Link',
        oldAddress: 'Old WebSite',
        douyin: 'Tiktok',
        worktime: 'Worktime',
        timeFrame: 'Monday To Friday 9:00 ~ 18:00',
        weChat: 'WeChat',
        weChatAccount: 'WeChat Account',
        customerService: 'Customer Wechat'
    },

    //菜单 
    nav: {
        product: 'Product',
        software: 'Download',
        contact: 'Contact Us'
    },
    productCenter: 'Product center',
    productR6: {
        name: 'iTing-R6',
        intro: 'Call recording | Call translation | Face to face translation'
    },
    productV8: {
        name: 'iTing-V8',
        intro: 'Voice typing | 100+global languages | 24 dialects' 
    },
    productPen: {
        name: 'Translation pen',
        intro: 'Intelligent voice recording | real-time bilingual subtitles | App visual control'
    },
    product113: {
        name: 'iTing-113',
        intro: 'App visual control | Bluetooth 5.2 | Strong compatibility'
    },
    detailNav: {
        detail: 'Details',
        specs: 'Specifications',
        faq: 'Faq'
    },
    color: 'color',
    downloadPage: {
        title: 'Download methods',
        descriptionOne: 'iTWS is a mobile phone software and an intelligent hardware service platform for mobile phone peripherals. Support Hongmeng OS, iOS and Android systems. It has supported Bluetooth speakers, intelligent recording pens, translation pens, recording translation earphones, Zhongke Bluetext Bluetooth earphones, etc. More products will be supported and accessed in the future.', //iTws下载描述
        descriptionTwo: 'AiOffice is a computer software and a software service platform for AI office products. It supports large windows, macOS and Android screens. The software has been connected to AI mouse, AI keyboard, AI presenter, Zoom Assistant conference headset, AI omnidirectional microphone, etc. More products will be supported and accessed in the future.', //ai-office 下载描述
        website: 'Enter the official website',
        aiWebsite: 'Go to AI Office to download'
    },
    company:{
        title: 'Company Information',
        companyInfo: "The company is committed to creating a new generation of 'simple, efficient and friendly' intelligent office scenes, focusing on artificial intelligence, through the combination of software, hardware and cloud service technology, to improve people's work efficiency and happiness.",
        companyInfoMore: "Adhering to the mission of 'making work easier and more efficient', a series of intelligent office hardware products have been connected based on AiOffice and iTWS software, such as intelligent voice mouse, intelligent keyboard, intelligent page turning pen, intelligent recording pen, recording translation headset, and intelligent translation pen. The company's brand 'iTing AiTing' is the only certified smart office brand in Beijing and Tokyo.The company adheres to the principle of 'customer first, continuous innovation' to forge ahead and take off together with partners at home and abroad! ",
        titleEmail: 'Contact Email',
        email: 'support@iting.ai',
        overseasContact: '海外業務諮詢(Overseas business consulting):\n Come Success Intelligent Technology Co., Ltd\n Jacky Lin +886-963386111,\njacky.lin@comesuccess.co'
    }
}

export default en;