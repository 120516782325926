<template>
	<div>
		<BannerMobile v-if="curLanguage == 'zh'"></BannerMobile>
		<BannerMobileLang v-if="curLanguage != 'zh'"></BannerMobileLang>
		<div class="product-mobile">
			<h3 class="product-title">{{ $t('productCenter') }}</h3>
			<ul class="product-list">
				<li class="list-item">
					<router-link :to="{path: '/ProductDetailMobile/',query: {'id': 'iTingR6'}}">
						<div class="product-image">
							<img src="../../assets/images/product-headset.png" />
						</div>
						<h4 class="product-name">{{ $t('productR6.name') }}</h4>
						<p class="product-intro">{{ $t('productR6.intro') }}</p>
					</router-link>
				</li>
				<li class="list-item">
					<router-link :to="{path: '/ProductDetailMobile/',query: {'id': 'iTingV8'}}">
						<div class="product-image">
							<img src="../../assets/images/product-mouse.png" />
						</div>
						<h4 class="product-name">{{ $t('productV8.name') }}</h4>
						<p class="product-intro">{{ $t('productV8.intro') }}</p>
					</router-link>
				</li>
				<li class="list-item">
					<router-link :to="{path: '/ProductDetailMobile/',query: {'id': 'iTingPen'}}">
						<div class="product-image">
							<img src="../../assets/images/product-pen.png" />
						</div>
						<h4 class="product-name">{{ $t('productPen.name') }}</h4>
						<p class="product-intro">{{ $t('productPen.intro') }}</p>
					</router-link>
				</li>
				<li class="list-item">
					<router-link :to="{path: '/productDetail/',query: {'id': 'iTing113'}}">
						<div class="product-image">
							<img src="../../assets/images/product-soundbox.png" />
						</div>
						<h4 class="product-name">{{ $t('product113.name') }}</h4>
						<p class="product-intro">{{ $t('product113.intro') }}</p>
					</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import BannerMobile from './BannerMobile.vue';
	import BannerMobileLang from './BannerMobileLang.vue';
	import store from '@/store/index.js';

	export default{
		name: 'carrousel',
		components:{
			BannerMobile,
			BannerMobileLang
		},
		data() {
			return {
				
			}
		},
		computed:{ 
			curLanguage(){
				let language = '';
				let storeLanguage = store.getters['getCurrentLanguage'];
				if(storeLanguage != ''){
					language = storeLanguage
				}else{
					language = this.$i18n.locale
				}
				return language;
			}			
		},
		methods:{
			callback(){
				console.log(1);
			},
			enterCaseBlock(index){
				if(index == 0)
					this.activeClass = 0
				else if(index == 1)
					this.activeClass = 1
				else if(index == 2)
					this.activeClass = 2
			},
			enterTechData(index){
				if(index == 0){
					this.activeTech = 0;
					this.techOne = 0;
				}else if(index == 1){
					this.activeTech = 1;
					this.techOne = 1;
				}else if(index == 2){
					this.activeTech = 2;
					this.techOne = 2;
				}
			}
		}
	}
</script>

<style lang="scss">
	
	
</style>
