
<template>
	<div class="top-distance">
		<!-- <div class="banner-download"></div> -->
		<div class="main-contact">
			<div class="company-info">
				<h4>{{ $t('company.title') }}</h4>
				<p>{{ $t('company.companyInfo') }}</p>
				<p>{{ $t('company.companyInfoMore') }}</p>			
			</div>
			<div class="contact-info">
				<h4>{{ $t('company.titleEmail') }}</h4>
				<p>{{ $t('company.email') }}</p>
				<p v-html="$t('company.overseasContact')" class="warp m-t20"></p>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'carrousel',

		data() {
			return {
				
			}
		},
		
		methods:{
			
		}
	}
</script>

<style lang="scss">
	
	
</style>
