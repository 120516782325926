<template>
	<div class="top-distance">
		<!-- <div class="banner-download"></div> -->
		<div class="main-download-mobile">
			<div class="iTws-download">
				<h4 class="iTws"><span class="color-o">ITWS</span> {{ $t('downloadPage.title') }}</h4>
				<p class="download-text">{{ $t('downloadPage.descriptionOne') }}</p>
				<div class="app-market">
					<ul class="market-list">
						<li class="box-shadow">
							<a href="https://apps.apple.com/cn/app/itws/id1587119305" target="_blank">
								<img src="../../assets/images/d-app.png" />
								<p class="to-web">{{ $t('downloadPage.website') }}</p>
							</a>					
						</li>
						<li class="box-shadow">
							<a href="https://play.google.com/store/apps/details?id=com.qjzn.earphone" target="_blank">
								<img src="../../assets/images/d-google.png" />
								<p class="to-web">{{ $t('downloadPage.website') }}</p>
							</a>					
						</li>
						<li class="box-shadow">
							<a href="https://appgallery.huawei.com/app/C104680139" target="_blank">
								<img src="../../assets/images/d-huawei.png" />
								<p class="to-web">{{ $t('downloadPage.website') }}</p>
							</a>					
						</li>
						<li class="box-shadow">
							<a href="https://app.mi.com/details?id=com.qjzn.earphone&ref=search" target="_blank">
								<img src="../../assets/images/d-mi.png" />
								<p class="to-web">{{ $t('downloadPage.website') }}</p>
							</a>					
						</li>
						<li class="box-shadow">
							<a href="http://h5.appstore.vivo.com.cn/#/details?search_word=itws&search_action=4&app_id=3439274&app_pos=1&source=5&appId=3439274&frompage=searchResultApp&listpos=1" target="_blank">
								<img src="../../assets/images/d-vivo.png" />
								<p class="to-web">{{ $t('downloadPage.website') }}</p>
							</a>					
						</li>
						<!-- <li class="box-shadow">
							<a href="" target="_blank">
								<img src="../../assets/images/d-oppo.png" />
								<p class="to-web">{{ $t('downloadPage.website') }}</p>
							</a>					
						</li> -->
					</ul>
				</div>
			</div>
			<div class="ai-office-download">
				<h4 class="ai-office"><span class="color-o">Ai Office</span> {{ $t('downloadPage.title') }}</h4>
				<p class="download-text">{{ $t('downloadPage.descriptionTwo') }}</p>
				<div class="download-link">
					<a href="https://www.aioffice.ltd/" target="_blank">{{ $t('downloadPage.aiWebsite') }}</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'carrousel',

		data() {
			return {
				
			}
		},
		
		methods:{
			
		}
	}
</script>

<style lang="scss">
	
	
</style>
