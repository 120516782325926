<template>
	<div id="app">
		<Index v-if="!isMobile"></Index>
		<IndexMobile v-if="isMobile"></IndexMobile>
	</div>
</template>

<script>
	import Index from './components/Index.vue'
	import IndexMobile from './components/IndexMobile.vue';

	export default {
		name: 'App',
		components: {
			Index,
			IndexMobile
		},
		meta:{
			title: 'iTing'
		},
		data(){
			return{
				isMobile: false,
			}
		},
		mounted(){
			let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
			if(flag){
				this.isMobile = true;
			}else{
				this.isMobile = false;
			}
		}
	}
</script>

<style lang="scss">
@import '~@/assets/css/common.scss';
#app{
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
