const zh = {
    lang: '中文',  //当前语言
    languages: [   //语言文件
        {
            value: 'zh',   //value为语言包文件名（export default zh）
            name: '中文'    //语言名称
        },
        {
            value: 'en',
            name: 'English'
        }
    ],

    //以下为内容区域翻译      //中文
    bannerOne: {
        title: '智能语音鼠标•V8',
        introduce: '让工作更轻松高效',
        explain1: '智慧办公——以人工智能技术为基石',
    },
    contact: {
        link: '友情链接',
        oldAddress: '原下载网页地址',
        douyin: '抖音号',
        worktime: '工作时间',
        timeFrame: '周一至周五 9:00 ~ 18:00',
        weChat: '微信同号',
        weChatAccount: '微信公众号',
        customerService: '客服微信'
    },
    //菜单 
    nav: {
        product: '产品',
        software: '软件下载',
        contact: '联系我们'
    },
    productCenter: '产品中心',
    productR6: {
        name: 'iTing-R6',
        intro: '通话录音 | 通话翻译 | 面对面翻译'
    },
    productV8: {
        name: 'iTing-V8',
        intro: '语音打字 | 100+全球语言 | 24种方言' 
    },
    productPen: {
        name: '翻页笔',
        intro: '智能语音记录 | 实时双语字幕 | App可视化控制'
    },
    product113: {
        name: 'iTing-113',
        intro: 'App可视化控制 | 蓝牙5.2 | 兼容强'
    },
    detailNav: {
        detail: '详情',
        specs: '规格参数',
        faq: '疑问解答'
    },
    color: '颜色',
    downloadPage: {
        title: '下载方式',
        descriptionOne: 'iTWS是一款手机软件，手机外设的智能硬件服务平台。支持鸿蒙OS, iOS, Android 系统。已支持蓝牙音箱、智能录音笔、翻译笔、录音翻译耳机、中科蓝讯蓝牙耳机等。未来会支持和接入更多的产品。', //iTws下载描述
        descriptionTwo: 'AiOffice是一款电脑端软件，智慧办公产品的软件服务平台。支持window，macOS, Android大屏。软件已接入智能鼠标，智能键盘，智能演示器，Zoom Assistant会议耳机，智能全向麦等。未来会支持和接入更多产品。', //ai-office 下载描述
        website: '进入官网',
        aiWebsite: '前往AI Office下载'
    },
    company:{
        title: '公司信息',
        companyInfo: '公司致力于以人工智能为中心，通过软件、硬件、云服务技术结合应用到办公产品，打造新一代“简单、高效、友好”智慧办公场景，提升人们的工作效率和幸福感。',
        companyInfoMore: '秉承“让工作更轻松高效”的使命，目前基于AiOffice和iTWS软件已连接了智能语音鼠标、智能键盘、智能翻页笔、智能录音笔、录音翻译耳机、智能翻译笔等系列智慧办公硬件产品。公司旗下品牌“iTing 艾听”为京东京选唯一认证的智能办公品牌。公司坚持“客户至上，持续创新”砥砺前行，携手海内外广大合作伙伴一起腾飞！',
        titleEmail: '联系邮箱',
        email: 'service@iTing.ai',
        overseasContact: '海外業務諮詢(Overseas business consulting):\n Come Success Intelligent Technology Co., Ltd\n Jacky Lin +886-963386111,\njacky.lin@comesuccess.co'
    }
}

export default zh;