<template>
	<div class="swiper swiper-mobile">
		<swiper :options="swiperOption" class="swiper-container" ref="bannerSwiper" @someSwiperEvent="callback">
			<swiper-slide>
				<a href="javascript:;">
					<div class="banner-bg">
						<div class="banner-content">
							<div class="banner-text">
								<h3 class="banner-title">录音翻译耳机-<span class="color-o">R6</span></h3>
								<p class="banner-intro">会议通话翻译<span class="color-o font-w600">好帮手</span></p>
								<p class="brief-explain m-top">通话录音 | 通话翻译 | 面对面翻译</p>
								<div>
									<!-- <button class="more-btn font-w600">了解更多</button> -->
								</div>
							</div>
							<div class="banner-product">
								<img src="../../assets/images/ai-img-R6.png" />
							</div>
					    </div>
					</div>
				</a>
			</swiper-slide>	
			<swiper-slide>
				<a href="javascript:;">
					<div class="banner-bg">
						<div class="banner-content">
							<div class="banner-text">
								<h3 class="banner-title">智能语音鼠标-<span class="color-o">V8</span></h3>
								<p class="banner-intro">一分钟可打<span class="color-o font-w600">400字</span></p>
								<p class="brief-explain m-top">语音打字 | 100+全球语言 | 24种方言</p>
								<div>
									<!-- <button class="more-btn font-w600">了解更多</button> -->
								</div>
							</div>
							<div class="banner-product">
								<img src="../../assets/images/ai-img-mouse.png" />
							</div>
					    </div>
					</div>	
				</a>
			</swiper-slide>
			
		</swiper>
		<div class="swiper-pagination">
			<!-- <div class="page"></div> -->
		</div>    <!-- 分页 -->		
	</div>
</template>

<script>
	
    import Swiper from 'swiper'
	export default{
		name: 'carrousel',
		data() {
			return {
				swiperOption: {
					loop: true,
					speed: 3000,
					autoplay: {
						delay: 8000,
						disableOnInteraction: false
					},
					effect: 'slide',
					sliderPerView: 6,
					pagination: {
						el: '.swiper-pagination',
						type: 'bullets',
						clickable: true,
						dynamicBullets: false,
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
						hideOnClick: true,
					}
					
				}
			}
		},
		computed: {
			swiper(){
				return this.$refs.bannerSwiper.$swiper    //这里需要加上$  $swiper
			}
		},
		mounted(){
			console.log('current', this.swiper);
			new Swiper ('.swiper-container', {
				loop: true,
				speed: 3000,
				autoplay: {
					delay: 8000,
					disableOnInteraction: true
				},
				// 如果需要分页器
				pagination: '.swiper-pagination',
				// 如果需要前进后退按钮
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				// 如果需要滚动条
				scrollbar: '.swiper-scrollbar',
			})
			//this.$swiper.slideTo(1,3000,true)
		},
		methods:{
			callback(){
	
			}
		}
	}
</script>

<style>
	.swiper{
		--swiper-theme-color: #ff6600;
		--swiper-pagination-color: #FF6A13;/* 两种都可以 */
		--swiper-navigation-size: 20px;
	}
	.swiper-button-prev, .swiper-button-next{
		color: #FF6A13 !important;
		background: rgba(255, 255, 255, 0.2);
		padding: 8px 5px;
		border-radius: 10px ;
	}
	.swiper-pagination{
        left: 50%;
		transform: translateX(-50%);
		bottom: 10px;
	}
	.swiper-pagination-bullet{
		margin: 0 5px;
		width: 14px !important;
		height: 14px !important;
	}
</style>
