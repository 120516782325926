<template>
	<div class="top-distance">
		<div class="product-detail-mobile">
			<!-- 详细菜单 -->
			<div class="detail-top">
				<h5 class="detail-title">{{productInfo.title}}</h5>
				<p class="suit">{{productInfo.instructions}}</p>
			</div>
			<!-- 产品介绍 -->
			<div class="product-info">
				<div class="p-images">
					<div class="big-image" id="bigImage">
						<img :src="currentImage" />
					</div>
					<div class="small-images">
						<div class="small-img" :class="{'cur-image': isHover === 1}">
							<img :src="productInfo.imageOne" @mouseover="getImageSrc(productInfo.imageOne, 1)" />
						</div>
						<!-- <div class="small-img" :class="{'cur-image': isHover === 2}">
							<img :src="productInfo.imageTwo" @mouseover="getImageSrc(productInfo.imageTwo, 2)"/>
						</div>
						<div class="small-img" :class="{'cur-image': isHover === 3}">
							<img :src="productInfo.imageThree" @mouseover="getImageSrc(productInfo.imageThree, 3)"/>
						</div>
						<div class="small-img" :class="{'cur-image': isHover === 4}">
							<img :src="productInfo.imageFour" @mouseover="getImageSrc(productInfo.imageFour, 4)"/>
						</div>
						<div class="small-img" :class="{'cur-image': isHover === 5}">
							<img :src="productInfo.imageFive" @mouseover="getImageSrc(productInfo.imageFive, 5)"/>
						</div> -->
					</div>
				</div>
				<div class="detail-nav">
					<a href="javascript:;" :class="{'current-nav': isCurrent === 1}" @click="isCurrent = 1">{{ $t('detailNav.detail') }}</a>|
					<a href="javascript:;" :class="{'current-nav': isCurrent === 2}" @click="(isCurrent = 2)">{{ $t('detailNav.specs') }}</a>|
					<a :href="productInfo.faq" target="_blank">{{ $t('detailNav.faq') }}</a>
				</div>
				<!-- <div class="product-text">
					<h4 class="product-title">{{productInfo.title}}</h4>
					<p class="suit">{{productInfo.instructions}}</p>
					<h4 class="product-color">{{$t('color')}}</h4>
					<div class="color-image">
						<img :src="productInfo.colorImage" />
					</div>
				</div> -->
			</div>
			<!-- 图片 -->
			<div class="product-detail-img" v-show="isCurrent === 1">
				<div v-for="(img, index) in productInfo.detailImages" :key="index">
					<img :src="img" alt=""/>
				</div>	
			</div>
			<!-- 规格参数 -->
			<div class="specs" v-show="isCurrent === 2">
				<h3 class="specs-title">规格参数</h3>
				<div class="specs-params">
					<div class="left-param" >
						<div class="param-item" v-for="(leftSpec, lIndex) in productInfo.specsLeft" :key="lIndex">
							<h5>{{ leftSpec.name }}</h5>
							<p>{{ leftSpec.value }}</p>
						</div>					
					</div>
					<div class="right-param">
						<div class="param-item" v-for="(rightSpec, rIndex) in productInfo.specsRight" :key="rIndex">
							<h5>{{ rightSpec.name }}</h5>
							<p>{{ rightSpec.value }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import dataZh from '@/components/product/data/data-zh.json';
	import dataEn from '@/components/product/data/data-en.json';
	import store from '@/store/index.js';
	export default{
		name: 'carrousel',
		data() {
			return {
				isCurrent: 1,
				//默认大图
				currentImage: '',
				//小图
				isHover: 1,
				productInfo: {}
			}
		},
		computed:{ 
			curLanguage(){
				let language = '';
				let storeLanguage = store.getters['getCurrentLanguage'];
				if(storeLanguage != ''){
					language = storeLanguage
				}else{
					language = this.$i18n.locale
				}
				return language;
			}			
		},
		mounted(){
			console.log(this.$route.query)
			let param = this.$route.query;

			if(param.id == 'iTingR6'){
				if(this.curLanguage == 'zh'){
					this.productInfo = dataZh.iTingR6;
				}else{
					this.productInfo = dataEn.iTingR6;
				}
			}else if(param.id == 'iTingV8'){
				if(this.curLanguage == 'zh'){
					this.productInfo = dataZh.iTingV8;
				}else{
					this.productInfo = dataEn.iTingV8;
				}
			}else if(param.id == 'iTingPen'){
				if(this.curLanguage == 'zh'){
					this.productInfo = dataZh.iTingPen;
				}else{
					this.productInfo = dataEn.iTingPen;
				}
			}else if(param.id == 'iTing113'){
				if(this.curLanguage == 'zh'){
					this.productInfo = dataZh.iTing113;
				}else{
					this.productInfo = dataEn.iTing113;
				}
			}

			if(JSON.stringify(this.productInfo) != "{}"){
				this.currentImage = this.productInfo.imageOne
			}
		},
		methods:{
			//鼠标滑过图片切换
			getImageSrc(src, num){
				this.currentImage = src;
				this.isHover = num;
			}
		}
	}
</script>

<style lang="scss">
	
	
</style>
