<template>
	<div>
		<!-- <Header></Header> -->
		<div class="tipContent" v-if="isTip">您当前使用的浏览器版本过低，可能会出现界面显示异常或功能无法正常使用等问题，建议您更换为更高版本的浏览器</div>
		<div class="header" id="header" :class="{'header-bg': isScrolled}">
			<div class="head-content" :class="{'head-content-bg': isScrolled}">
				<div class="ai-logo">
					<a href="/">
						<img src="../assets/images/iTing-img-logo1.png" alt="">
					</a>
				</div>
				<div class="head-nav">
					<ul>
						<li class="nav-text" :class="{'cur-nav': curNav === 1}" @click="selectNav(1)">
							<router-link to="/">{{ $t('nav.product') }}</router-link>
						</li>
						<li class="nav-text" :class="{'cur-nav': curNav === 2}" @click="selectNav(2)">
							<router-link to="/softwareDownload">{{ $t('nav.software') }}</router-link>
						</li>
						<li class="nav-text" :class="{'cur-nav': curNav === 3}" @click="selectNav(3)">
							<router-link to="/contactUs">{{ $t('nav.contact') }}</router-link>
						</li>
					</ul>
				</div>
				<div class="language-change" @mouseenter="languageEnter" @mouseleave="languageLeave">
					<a class="lang-title" href="javascript:;">{{$t('lang')}}</a>
					<div class="language-level" v-show="showLanguage">
						<ul v-for="(lang,index) in $t('languages')" :key="index">
							<li><a class="lang" href="javascript:;" @click="changeLanguage(lang.value)">{{lang.name}}</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<router-view v-if="isRefresh"></router-view>
		<div class="foot">
			<div class="foot-content">
				<div class="ai-logo-foot">
					<img src="../assets/images/iTing-img-logo1.png" alt=""/>
				</div>
				<div class="foot-nav">
					<div class="foot-nav-left">
                        
                        <ul class="foot-nav-ul">
                            <li class="contact-way">
                                <h5>{{$t('contact.link')}}</h5>
                                <p><a href="https://www.aioffice.ltd/" target="_blank">{{$t('contact.oldAddress')}}</a></p>
                            </li>
                        </ul>
					</div>
					<div class="foot-nav-right">
						<div class="work-time worktime">
							<h5>{{$t('contact.worktime')}}</h5>
							<p>{{$t('contact.timeFrame')}}</p>
						</div>						
						<ul class="foot-nav-qrcode">
							<li class="qrcode">
								<div class="qrcode-img">
									<img src="../assets/images/ai-img-douyin-bg.png" alt="" />
								</div>
								<p>{{$t('contact.douyin')}}</p>
							</li>
							<li class="qrcode">
								<div class="qrcode-img">
									<img src="../assets/images/ai-img-qcode.png" alt="" />
								</div>
								<p>{{$t('contact.weChatAccount')}}</p>
							</li>
							<li class="qrcode">
								<div class="qrcode-img">
									<img src="../assets/images/ai-img-service.png" alt="" />
								</div>
								<p>{{$t('contact.customerService')}}</p>
							</li>
						</ul>
					</div>
				</div>
				<p class="record-no">Copyright@ 粤ICP备 2022076506号-2</p>
			</div>
		</div>
		<HangLayer></HangLayer>
	</div>
</template>

<script>
	//import Header from './content/Header.vue'
    import store from '@/store/index.js';
	import HangLayer from './common/HangLayer.vue';

	export default {
		components: {
			HangLayer
		},
		data(){
			return{
				scroll: '',
				isScrolled: false,
				lang: '',
				showLanguage: false,
				curNav: 1,
				isRefresh: true,
				isTip: false, //低版本浏览器提示
			}
		},
		created(){
			//添加meta适配标签代码
			document
			.querySelector('meta[name="viewport"]')
			.setAttribute('content', 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no');
			//获取浏览器信息，并判断版本
			let DEFAULT_VERSION = 9.0;
			let ua = navigator.userAgent.toLowerCase();
			let isIE = ua.indexOf("msie") > -1;
			let version = '';
			console.log(isIE + '0000')
			if(isIE){
				version = ua.match(/msie([\d.]+)/)[1];
				if(version <= DEFAULT_VERSION){
					this.isTip = true;
				}
			}
			
		},
		mounted() {
			document.title = 'iTing';  // 浏览器标签title
			//监听鼠标滚动事件
			window.addEventListener('scroll', this.debounce(this.handleScroll,30), true)||window.addEventListener("DOMMouseScroll",this.debounce(this.handleScroll,30),false);
			//刷新的时候获取路由名称
			if(this.$route.name == 'product' || this.$route.name == 'productDetail'){
				this.curNav = 1;
			}else if(this.$route.name == 'download'){
				this.curNav = 2;
			}else if(this.$route.name == 'contact'){
				this.curNav = 3;
			}
 			
		},
		provide() {
			return {
				refresh: this.refresh
			}
		},
		methods:{
			selectNav(index){
				this.curNav = index;
			},
			//函数防抖
			debounce(func, wait) {
				let timeout;
				return function () {
					let context = this;
					let args = arguments;
					if (timeout) clearTimeout(timeout);
					timeout = setTimeout(() => {
						func.apply(context, args)
					}, wait);
				}
			},
			//判断滚动方向
			handleScroll(){
				this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
				if(this.scroll > 0){
					//document.querySelector('#header').style.backgroundImage = 'rgba(0,0,0,0.2)';
					this.isScrolled = true;
				}else{
					//document.querySelector('#header').style.backgroundColor = 'transparent';
					this.isScrolled = false;
				}
			},
			
			//语言界面
			languageEnter(){
                this.showLanguage = true
			},
			languageLeave(){
				this.showLanguage = false
			},
			changeLanguage(lang){
				//console.log(lang)
				if(this.$i18n.locale != lang){
                    this.$root.$i18n.locale = lang;
					store.commit('setCurrentLanguage', lang);
					this.refresh();
				}
			},
			refresh() {
				this.isRefresh = false;
				this.$nextTick(() => {
					this.isRefresh = true;
				})
			}
		},	
	}
</script>

<style lang="scss">
</style>
