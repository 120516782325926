<template>
	<div class="swiper swiper-pc">
		<swiper :options="swiperOption" ref="bannerSwiper" @someSwiperEvent="callback" class="swiper-container">
			<swiper-slide>
				<a href="javascript:;">
					<div class="banner-bg">
						<div class="banner-content">
							<div class="banner-text">
								<h3 class="banner-title">{{$t('bannerOne.title')}}</h3>
								<p class="banner-intro wrap-text color-o">{{$t('bannerOne.introduce')}}</p>
								<p class="brief-explain wrap-text m-top">{{$t('bannerOne.explain1')}}</p>
								<div>
									<!-- <button class="more-btn font-w600">Learn more</button> -->
								</div>
							</div>
							<div class="banner-product">
								<img src="../../assets/images/ai-img-ej.png" />
							</div>
					    </div>
					</div>
				</a>
			</swiper-slide>
			<swiper-slide>
				<a href="javascript:;">
					<div class="banner-bg">
						<div class="banner-content">
							<div class="banner-text">
								<h3 class="banner-title">{{$t('bannerTwo.title')}}</h3>
								<p class="banner-intro wrap-text color-o">{{$t('bannerTwo.introduce')}}</p>
								<p class="brief-explain wrap-text m-top">{{$t('bannerTwo.explain1')}}</p>
								<div>
									<!-- <button class="more-btn font-w600">Learn more</button> -->
								</div>
							</div>
							<div class="banner-product">
								<img src="../../assets/images/ai-img-mouse.png" />
							</div>
					    </div>
					</div>	
				</a>
			</swiper-slide>
					
		</swiper>
		<div class="swiper-pagination"></div>    <!-- 分页 -->	
	</div>
</template>

<script>
	import Swiper from 'swiper'
	export default{
		name: 'carrousel',
		data() {
			return {
				swiperOption: {
					loop: true,
					speed: 1000,
					autoplay: {
						delay: 8000,
						disableOnInteraction: false
					},
					effect: 'slide',
					sliderPerView: 6,
					pagination: {
						el: '.swiper-pagination',
						type: 'bullets',
						clickable: true,
						dynamicBullets: false,
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
						hideOnClick: true,
					}
					
				}
			}
		},
		computed: {
			swiper(){
				return this.$refs.bannerSwiper.$swiper    //这里需要加上$  $swiper
			}
		},
		mounted(){
			console.log('current', this.swiper);
			new Swiper ('.swiper-container', {
				loop: true,
				speed: 1000,
				autoplay: {
					delay: 8000,
					disableOnInteraction: true
				},
				// 如果需要分页器
				pagination: '.swiper-pagination',
				// 如果需要前进后退按钮
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				// 如果需要滚动条
				scrollbar: '.swiper-scrollbar',
			})
			//this.swiper.slideTo(1,3000,false)
		},
		methods:{
			callback(){
	
			},
		}
	}
</script>

<style>
	.swiper{
		--swiper-theme-color: #ff6600;
		--swiper-pagination-color: #FF6A13;/* 两种都可以 */

		--swiper-navigation-size: 16px;
	}
	.swiper-button-prev, .swiper-button-next{
		color: #FF6A13 !important;
		background: rgba(255, 255, 255, 0.2);
		padding: 8px 5px;
		border-radius: 10px ;
	}
	.swiper-pagination{
        left: 50%;
		bottom: 10px;
		text-align: center;
	}
</style>


<!-- banner原内容备份 -->
<!-- <div class="banner-content">
	<div class="banner-text">
		<h3 class="banner-title">Intelligent voice mouse • V8</h3>
		<p class="banner-intro wrap-text color-grey">You can type 400 words a minute</p>
		<p class="brief-explain wrap-text">vocie typing | 100+global languages | 24 dialects</p>
		<div>
			<button class="more-btn font-w600">Learn more</button>
		</div>
	</div>
	<div class="banner-product">
		<img src="../../assets/images/ai-img-mouse.png" />
	</div>
</div> -->