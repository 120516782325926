
import IndexContent from '../components/common/IndexContent.vue';
import ProductDetail from '../components/product/ProductDetail.vue';
import ContactUs from '../components/contact/ContactUs.vue';
import SoftwareDownload from '../components/download/SoftwareDownload.vue';

//移动端
import MobileContent from '../components/mobile/MobileContent.vue';
import ProductDetailMobile from '../components/mobile/ProductDetailMobile';
import SoftwareDownloadMobile from '../components/mobile/SoftwareDownloadMobile';
import ContactMobile from '../components/mobile/ContactMobile';

const routesPC = [
	{
		path: '/', name:'product', component: IndexContent
	},
	{
		path: '/productDetail', name:'productDetail', component: ProductDetail
	},
	{
		path: '/contactUs', name:'contact', component: ContactUs
	},
	{
		path: '/softwareDownload', name:'download', component: SoftwareDownload
	},	
]
//移动端
const routesM = [
	{
		path: '/', name:'product', component: MobileContent
	},
	{
		path: '/ProductDetailMobile', name:'productDetail', component: ProductDetailMobile
	},
	{
		path: '/SoftwareDownloadMobile', name:'download', component: SoftwareDownloadMobile
	},
	{
		path: '/ContactMobile', name:'contact', component: ContactMobile
	}
]

let routes = []
if(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)){
	routes = routesM
}else{
	routes = routesPC
}
export {
	routes
}