<template>
	<div class="hang-layer" v-show="isVisible">
		<div class="back-top box-shadow" @click="backToTop">
			<img  src="../../assets/images/top.png" alt="" />
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				scroll: '',
				isVisible: false
			}
		},
		mounted() {
			//监听鼠标滚动事件
			window.addEventListener('scroll', this.isVisibleBackIcon)||window.addEventListener("DOMMouseScroll", this.isVisibleBackIcon)
		},
		methods:{
			isVisibleBackIcon(){
				//判断滚动方向
				this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
				if(this.scroll > 50){
					this.isVisible = true;
				}else{
					this.isVisible = false;
				}
			},
			backToTop(){
				document.documentElement.scrollTop = 0 ;
				document.body.scrollTop = 0;
				this.isVisible = false;
			}
		}
	}
</script>

<style>
</style>
